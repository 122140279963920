/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import useDarkMode from "@fisch0920/use-dark-mode"
// import Context from "../store/context"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  // const darkMode = useDarkMode(false);

  // const {state} =useContext(Context)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    // <div className={`${state.isDark ? 'dark' : ''}`}>
    <div>
    <div className={`transition-color duration-300 dark:bg-slate-700 dark:text-gray-100 min-h-screen`}>
      <Header siteTitle={data.site.siteMetadata.title} />
        <div className="m-auto max-w-7xl w-full mt-12 lg:mt-0 px-6 mb-14">
          <StaticImage className="w-full h-36 rounded-3xl shadow-xl dark:brightness-75" src="../images/sime-basioli-BRkikoNP0KQ-unsplash.jpg" alt="" />
        </div>
      <div
        className="container max-w-6xl mx-auto px-4"
      >
        <main className="block min-h-screen pb-48">{children}</main>
      </div>
      <Footer />
    </div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
                <symbol id="icon-dark-mode" viewBox="0 0 24 24">
                  <title>Dark Mode</title>
                  <path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"/>
                </symbol>
              </defs>
            </svg>
            
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
                <symbol id="icon-light-mode" viewBox="0 0 24 24">
                  <title>Light Mode</title>
                  <path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"/>
                </symbol>
              </defs>
            </svg>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{position: "absolute", width: 0, height: 0}} width="0" height="0">
              <defs>
            <symbol id="logo" viewBox="0 0 413 58">
    <g transform="matrix(1,0,0,1,-396.486,-29.7114)">
        <g transform="matrix(1.06162,0,0,1.06162,-26.8231,-7.52341)">
            <g transform="matrix(27.4754,0,0,27.4754,398.583,58.1415)">
                <path d="M0.361,-0.449C0.421,-0.428 0.462,-0.403 0.484,-0.372C0.507,-0.341 0.518,-0.299 0.518,-0.245C0.518,-0.162 0.497,-0.101 0.456,-0.061C0.415,-0.02 0.351,-0 0.264,-0L0.025,-0C0.016,-0 0.011,-0.005 0.011,-0.015L0.011,-0.044C0.011,-0.053 0.015,-0.059 0.024,-0.063C0.042,-0.071 0.052,-0.084 0.052,-0.101L0.052,-0.69C0.052,-0.699 0.05,-0.704 0.048,-0.707C0.045,-0.71 0.037,-0.715 0.024,-0.721C0.018,-0.724 0.014,-0.727 0.013,-0.73C0.012,-0.734 0.011,-0.743 0.011,-0.757C0.011,-0.769 0.013,-0.778 0.016,-0.782C0.02,-0.786 0.026,-0.788 0.034,-0.788L0.302,-0.788C0.366,-0.788 0.416,-0.772 0.45,-0.739C0.485,-0.706 0.502,-0.664 0.502,-0.613C0.502,-0.571 0.488,-0.535 0.461,-0.507C0.434,-0.478 0.401,-0.459 0.361,-0.449ZM0.233,-0.733L0.233,-0.477L0.259,-0.477C0.276,-0.477 0.288,-0.481 0.298,-0.491C0.307,-0.5 0.312,-0.516 0.314,-0.538C0.315,-0.56 0.316,-0.588 0.316,-0.621C0.316,-0.668 0.311,-0.698 0.302,-0.712C0.294,-0.726 0.279,-0.733 0.259,-0.733L0.233,-0.733ZM0.233,-0.421L0.233,-0.056C0.263,-0.056 0.283,-0.06 0.294,-0.068C0.304,-0.076 0.31,-0.093 0.312,-0.117C0.314,-0.141 0.314,-0.181 0.314,-0.239C0.314,-0.25 0.314,-0.277 0.313,-0.319C0.312,-0.36 0.31,-0.385 0.307,-0.394C0.304,-0.402 0.299,-0.409 0.292,-0.414C0.284,-0.418 0.274,-0.421 0.259,-0.421L0.233,-0.421Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,413.475,58.1415)">
                <path d="M0.24,-0.347L0.24,-0.104C0.24,-0.093 0.241,-0.085 0.242,-0.082C0.244,-0.078 0.248,-0.074 0.255,-0.069C0.263,-0.064 0.268,-0.055 0.268,-0.042C0.268,-0.023 0.266,-0.011 0.262,-0.007C0.259,-0.002 0.253,-0 0.244,-0L0.038,-0C0.026,-0 0.02,-0.008 0.02,-0.025C0.02,-0.042 0.021,-0.052 0.021,-0.057C0.022,-0.062 0.026,-0.065 0.031,-0.067C0.049,-0.074 0.058,-0.088 0.058,-0.109L0.058,-0.681C0.058,-0.702 0.05,-0.715 0.035,-0.72C0.027,-0.722 0.022,-0.725 0.02,-0.727C0.018,-0.73 0.017,-0.738 0.017,-0.75C0.017,-0.775 0.024,-0.788 0.038,-0.788L0.308,-0.788C0.361,-0.788 0.409,-0.768 0.451,-0.728C0.494,-0.687 0.515,-0.633 0.515,-0.565C0.515,-0.468 0.465,-0.405 0.367,-0.375C0.457,-0.356 0.502,-0.299 0.502,-0.204L0.502,-0.088C0.502,-0.078 0.506,-0.071 0.513,-0.066C0.522,-0.06 0.526,-0.051 0.526,-0.039C0.526,-0.02 0.525,-0.009 0.523,-0.005C0.521,-0.002 0.515,-0 0.505,-0L0.374,-0C0.358,-0 0.346,-0.009 0.335,-0.027C0.325,-0.044 0.32,-0.066 0.32,-0.092L0.32,-0.289C0.32,-0.327 0.301,-0.347 0.262,-0.347L0.24,-0.347ZM0.24,-0.732L0.24,-0.403L0.272,-0.403C0.304,-0.403 0.32,-0.436 0.32,-0.5L0.32,-0.658C0.32,-0.685 0.315,-0.704 0.305,-0.716C0.295,-0.727 0.279,-0.732 0.257,-0.732L0.24,-0.732Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,428.353,58.1415)">
                <path d="M0.248,-0.168L0.127,-0.168L0.121,-0.124C0.121,-0.123 0.121,-0.121 0.12,-0.119C0.117,-0.11 0.116,-0.101 0.116,-0.094C0.116,-0.082 0.124,-0.072 0.139,-0.064C0.147,-0.06 0.152,-0.055 0.154,-0.051C0.156,-0.046 0.157,-0.039 0.157,-0.028C0.157,-0.015 0.156,-0.007 0.155,-0.004C0.153,-0.001 0.15,-0 0.144,-0L0.016,-0C0.008,-0 0.005,-0.007 0.005,-0.022C0.005,-0.04 0.011,-0.055 0.022,-0.066C0.033,-0.076 0.039,-0.087 0.041,-0.098L0.131,-0.673C0.133,-0.682 0.133,-0.689 0.133,-0.696C0.133,-0.706 0.126,-0.715 0.112,-0.723C0.106,-0.727 0.103,-0.735 0.103,-0.749C0.103,-0.766 0.104,-0.778 0.105,-0.782C0.107,-0.786 0.113,-0.788 0.121,-0.788L0.308,-0.788C0.319,-0.788 0.327,-0.783 0.331,-0.772C0.336,-0.761 0.341,-0.738 0.345,-0.702C0.347,-0.688 0.349,-0.674 0.351,-0.66L0.431,-0.124C0.435,-0.097 0.445,-0.078 0.46,-0.064C0.464,-0.06 0.467,-0.058 0.468,-0.055C0.47,-0.053 0.47,-0.047 0.47,-0.036C0.47,-0.02 0.469,-0.009 0.467,-0.006C0.465,-0.002 0.46,-0 0.452,-0L0.235,-0C0.229,-0 0.226,-0.006 0.226,-0.019C0.226,-0.034 0.226,-0.044 0.228,-0.048C0.23,-0.052 0.234,-0.056 0.242,-0.062C0.253,-0.069 0.259,-0.078 0.259,-0.089C0.259,-0.1 0.256,-0.127 0.248,-0.168ZM0.19,-0.606L0.135,-0.225L0.243,-0.225L0.19,-0.606Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,441.513,58.1415)">
                <path d="M0.054,-0.788L0.361,-0.788C0.368,-0.788 0.371,-0.786 0.371,-0.782C0.371,-0.78 0.371,-0.778 0.371,-0.776C0.37,-0.775 0.369,-0.772 0.368,-0.768L0.21,-0.057L0.239,-0.057C0.256,-0.057 0.268,-0.063 0.276,-0.075C0.284,-0.087 0.291,-0.108 0.297,-0.14C0.301,-0.157 0.305,-0.167 0.31,-0.172C0.314,-0.176 0.323,-0.179 0.336,-0.179C0.353,-0.179 0.363,-0.178 0.366,-0.177C0.37,-0.175 0.371,-0.172 0.371,-0.167C0.371,-0.166 0.37,-0.159 0.368,-0.148L0.346,-0L0.028,-0C0.022,-0 0.019,-0.002 0.019,-0.005C0.019,-0.01 0.02,-0.017 0.022,-0.027L0.19,-0.733L0.156,-0.733C0.135,-0.733 0.118,-0.708 0.106,-0.656C0.102,-0.637 0.097,-0.625 0.091,-0.62C0.085,-0.616 0.074,-0.614 0.059,-0.614C0.047,-0.614 0.039,-0.614 0.035,-0.615C0.032,-0.617 0.03,-0.62 0.03,-0.625C0.03,-0.628 0.031,-0.633 0.032,-0.642L0.054,-0.788Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,452.098,58.1415)">
                <path d="M0.271,-0.801C0.319,-0.801 0.361,-0.785 0.396,-0.753C0.431,-0.721 0.459,-0.675 0.481,-0.616C0.504,-0.557 0.515,-0.481 0.515,-0.388C0.515,-0.277 0.493,-0.183 0.45,-0.106C0.407,-0.03 0.346,0.009 0.268,0.009C0.193,0.009 0.134,-0.029 0.091,-0.104C0.048,-0.178 0.027,-0.275 0.027,-0.394C0.027,-0.515 0.049,-0.613 0.093,-0.688C0.137,-0.764 0.197,-0.801 0.271,-0.801ZM0.31,-0.686C0.31,-0.726 0.297,-0.747 0.271,-0.747C0.259,-0.747 0.25,-0.742 0.242,-0.731C0.234,-0.721 0.23,-0.709 0.23,-0.694L0.23,-0.105C0.23,-0.085 0.234,-0.07 0.242,-0.06C0.25,-0.05 0.259,-0.044 0.27,-0.044C0.282,-0.044 0.292,-0.05 0.299,-0.062C0.306,-0.073 0.31,-0.086 0.31,-0.1L0.31,-0.686Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,466.976,58.1415)">
                <path d="M0.24,-0.347L0.24,-0.104C0.24,-0.093 0.241,-0.085 0.242,-0.082C0.244,-0.078 0.248,-0.074 0.255,-0.069C0.263,-0.064 0.268,-0.055 0.268,-0.042C0.268,-0.023 0.266,-0.011 0.262,-0.007C0.259,-0.002 0.253,-0 0.244,-0L0.038,-0C0.026,-0 0.02,-0.008 0.02,-0.025C0.02,-0.042 0.021,-0.052 0.021,-0.057C0.022,-0.062 0.026,-0.065 0.031,-0.067C0.049,-0.074 0.058,-0.088 0.058,-0.109L0.058,-0.681C0.058,-0.702 0.05,-0.715 0.035,-0.72C0.027,-0.722 0.022,-0.725 0.02,-0.727C0.018,-0.73 0.017,-0.738 0.017,-0.75C0.017,-0.775 0.024,-0.788 0.038,-0.788L0.308,-0.788C0.361,-0.788 0.409,-0.768 0.451,-0.728C0.494,-0.687 0.515,-0.633 0.515,-0.565C0.515,-0.468 0.465,-0.405 0.367,-0.375C0.457,-0.356 0.502,-0.299 0.502,-0.204L0.502,-0.088C0.502,-0.078 0.506,-0.071 0.513,-0.066C0.522,-0.06 0.526,-0.051 0.526,-0.039C0.526,-0.02 0.525,-0.009 0.523,-0.005C0.521,-0.002 0.515,-0 0.505,-0L0.374,-0C0.358,-0 0.346,-0.009 0.335,-0.027C0.325,-0.044 0.32,-0.066 0.32,-0.092L0.32,-0.289C0.32,-0.327 0.301,-0.347 0.262,-0.347L0.24,-0.347ZM0.24,-0.732L0.24,-0.403L0.272,-0.403C0.304,-0.403 0.32,-0.436 0.32,-0.5L0.32,-0.658C0.32,-0.685 0.315,-0.704 0.305,-0.716C0.295,-0.727 0.279,-0.732 0.257,-0.732L0.24,-0.732Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,481.854,58.1415)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,489.877,58.1415)">
                <path d="M0.248,-0.168L0.127,-0.168L0.121,-0.124C0.121,-0.123 0.121,-0.121 0.12,-0.119C0.117,-0.11 0.116,-0.101 0.116,-0.094C0.116,-0.082 0.124,-0.072 0.139,-0.064C0.147,-0.06 0.152,-0.055 0.154,-0.051C0.156,-0.046 0.157,-0.039 0.157,-0.028C0.157,-0.015 0.156,-0.007 0.155,-0.004C0.153,-0.001 0.15,-0 0.144,-0L0.016,-0C0.008,-0 0.005,-0.007 0.005,-0.022C0.005,-0.04 0.011,-0.055 0.022,-0.066C0.033,-0.076 0.039,-0.087 0.041,-0.098L0.131,-0.673C0.133,-0.682 0.133,-0.689 0.133,-0.696C0.133,-0.706 0.126,-0.715 0.112,-0.723C0.106,-0.727 0.103,-0.735 0.103,-0.749C0.103,-0.766 0.104,-0.778 0.105,-0.782C0.107,-0.786 0.113,-0.788 0.121,-0.788L0.308,-0.788C0.319,-0.788 0.327,-0.783 0.331,-0.772C0.336,-0.761 0.341,-0.738 0.345,-0.702C0.347,-0.688 0.349,-0.674 0.351,-0.66L0.431,-0.124C0.435,-0.097 0.445,-0.078 0.46,-0.064C0.464,-0.06 0.467,-0.058 0.468,-0.055C0.47,-0.053 0.47,-0.047 0.47,-0.036C0.47,-0.02 0.469,-0.009 0.467,-0.006C0.465,-0.002 0.46,-0 0.452,-0L0.235,-0C0.229,-0 0.226,-0.006 0.226,-0.019C0.226,-0.034 0.226,-0.044 0.228,-0.048C0.23,-0.052 0.234,-0.056 0.242,-0.062C0.253,-0.069 0.259,-0.078 0.259,-0.089C0.259,-0.1 0.256,-0.127 0.248,-0.168ZM0.19,-0.606L0.135,-0.225L0.243,-0.225L0.19,-0.606Z" />
            </g>
            <g transform="matrix(38.4655,0,0,38.4655,503.038,58.1415)">
                <path d="M0.236,-0.43L0.263,-0.43C0.278,-0.43 0.286,-0.425 0.286,-0.415C0.286,-0.406 0.28,-0.381 0.268,-0.339C0.256,-0.297 0.247,-0.272 0.241,-0.264C0.235,-0.256 0.227,-0.252 0.215,-0.252C0.209,-0.252 0.199,-0.252 0.183,-0.253C0.153,-0.256 0.131,-0.257 0.115,-0.257C0.1,-0.257 0.089,-0.252 0.082,-0.241C0.078,-0.237 0.075,-0.234 0.073,-0.233C0.07,-0.232 0.064,-0.231 0.054,-0.231C0.037,-0.231 0.027,-0.232 0.023,-0.234C0.019,-0.236 0.017,-0.241 0.017,-0.249C0.017,-0.259 0.022,-0.286 0.033,-0.329C0.044,-0.372 0.052,-0.395 0.057,-0.4C0.062,-0.405 0.074,-0.407 0.093,-0.407C0.102,-0.407 0.114,-0.407 0.13,-0.405C0.146,-0.404 0.16,-0.404 0.172,-0.404C0.186,-0.404 0.195,-0.405 0.2,-0.407C0.205,-0.409 0.212,-0.414 0.219,-0.421C0.224,-0.427 0.23,-0.43 0.236,-0.43Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,514.664,58.1415)">
                <path d="M0.234,-0.396L0.234,-0.1C0.234,-0.085 0.244,-0.074 0.263,-0.069C0.272,-0.066 0.278,-0.063 0.281,-0.06C0.283,-0.056 0.284,-0.049 0.284,-0.037C0.284,-0.019 0.283,-0.008 0.28,-0.005C0.277,-0.002 0.271,-0 0.26,-0L0.037,-0C0.024,-0 0.016,-0.003 0.012,-0.008C0.009,-0.013 0.007,-0.023 0.007,-0.039C0.007,-0.049 0.008,-0.056 0.01,-0.058C0.011,-0.061 0.017,-0.064 0.025,-0.068C0.037,-0.074 0.045,-0.078 0.048,-0.082C0.051,-0.086 0.052,-0.091 0.052,-0.099L0.052,-0.688C0.052,-0.697 0.051,-0.703 0.047,-0.708C0.044,-0.713 0.036,-0.717 0.025,-0.722C0.017,-0.726 0.013,-0.734 0.013,-0.746C0.013,-0.747 0.013,-0.755 0.013,-0.768C0.015,-0.781 0.023,-0.788 0.037,-0.788L0.341,-0.788C0.35,-0.788 0.357,-0.787 0.36,-0.784C0.364,-0.781 0.367,-0.774 0.369,-0.762C0.369,-0.76 0.369,-0.757 0.37,-0.754L0.392,-0.646C0.393,-0.639 0.394,-0.633 0.394,-0.628C0.394,-0.618 0.386,-0.612 0.371,-0.612C0.35,-0.612 0.337,-0.616 0.332,-0.624C0.326,-0.632 0.318,-0.651 0.307,-0.682C0.3,-0.701 0.294,-0.714 0.287,-0.72C0.28,-0.727 0.27,-0.73 0.256,-0.73C0.252,-0.73 0.245,-0.729 0.234,-0.729L0.234,-0.45L0.259,-0.45C0.272,-0.45 0.281,-0.453 0.286,-0.459C0.291,-0.465 0.295,-0.475 0.297,-0.49C0.3,-0.506 0.303,-0.517 0.306,-0.522C0.308,-0.526 0.319,-0.529 0.338,-0.529C0.351,-0.529 0.359,-0.528 0.362,-0.525C0.364,-0.522 0.366,-0.517 0.366,-0.51L0.366,-0.333C0.366,-0.326 0.364,-0.321 0.362,-0.318C0.359,-0.316 0.351,-0.315 0.337,-0.315C0.32,-0.315 0.309,-0.318 0.305,-0.323C0.301,-0.329 0.297,-0.342 0.295,-0.364C0.292,-0.386 0.28,-0.396 0.259,-0.396L0.234,-0.396Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,525.826,58.1415)">
                <path d="M0.271,-0.801C0.319,-0.801 0.361,-0.785 0.396,-0.753C0.431,-0.721 0.459,-0.675 0.481,-0.616C0.504,-0.557 0.515,-0.481 0.515,-0.388C0.515,-0.277 0.493,-0.183 0.45,-0.106C0.407,-0.03 0.346,0.009 0.268,0.009C0.193,0.009 0.134,-0.029 0.091,-0.104C0.048,-0.178 0.027,-0.275 0.027,-0.394C0.027,-0.515 0.049,-0.613 0.093,-0.688C0.137,-0.764 0.197,-0.801 0.271,-0.801ZM0.31,-0.686C0.31,-0.726 0.297,-0.747 0.271,-0.747C0.259,-0.747 0.25,-0.742 0.242,-0.731C0.234,-0.721 0.23,-0.709 0.23,-0.694L0.23,-0.105C0.23,-0.085 0.234,-0.07 0.242,-0.06C0.25,-0.05 0.259,-0.044 0.27,-0.044C0.282,-0.044 0.292,-0.05 0.299,-0.062C0.306,-0.073 0.31,-0.086 0.31,-0.1L0.31,-0.686Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,540.704,58.1415)">
                <path d="M0.24,-0.347L0.24,-0.104C0.24,-0.093 0.241,-0.085 0.242,-0.082C0.244,-0.078 0.248,-0.074 0.255,-0.069C0.263,-0.064 0.268,-0.055 0.268,-0.042C0.268,-0.023 0.266,-0.011 0.262,-0.007C0.259,-0.002 0.253,-0 0.244,-0L0.038,-0C0.026,-0 0.02,-0.008 0.02,-0.025C0.02,-0.042 0.021,-0.052 0.021,-0.057C0.022,-0.062 0.026,-0.065 0.031,-0.067C0.049,-0.074 0.058,-0.088 0.058,-0.109L0.058,-0.681C0.058,-0.702 0.05,-0.715 0.035,-0.72C0.027,-0.722 0.022,-0.725 0.02,-0.727C0.018,-0.73 0.017,-0.738 0.017,-0.75C0.017,-0.775 0.024,-0.788 0.038,-0.788L0.308,-0.788C0.361,-0.788 0.409,-0.768 0.451,-0.728C0.494,-0.687 0.515,-0.633 0.515,-0.565C0.515,-0.468 0.465,-0.405 0.367,-0.375C0.457,-0.356 0.502,-0.299 0.502,-0.204L0.502,-0.088C0.502,-0.078 0.506,-0.071 0.513,-0.066C0.522,-0.06 0.526,-0.051 0.526,-0.039C0.526,-0.02 0.525,-0.009 0.523,-0.005C0.521,-0.002 0.515,-0 0.505,-0L0.374,-0C0.358,-0 0.346,-0.009 0.335,-0.027C0.325,-0.044 0.32,-0.066 0.32,-0.092L0.32,-0.289C0.32,-0.327 0.301,-0.347 0.262,-0.347L0.24,-0.347ZM0.24,-0.732L0.24,-0.403L0.272,-0.403C0.304,-0.403 0.32,-0.436 0.32,-0.5L0.32,-0.658C0.32,-0.685 0.315,-0.704 0.305,-0.716C0.295,-0.727 0.279,-0.732 0.257,-0.732L0.24,-0.732Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,555.582,58.1415)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,577.871,58.1415)">
                <path d="M0.361,-0.449C0.421,-0.428 0.462,-0.403 0.484,-0.372C0.507,-0.341 0.518,-0.299 0.518,-0.245C0.518,-0.162 0.497,-0.101 0.456,-0.061C0.415,-0.02 0.351,-0 0.264,-0L0.025,-0C0.016,-0 0.011,-0.005 0.011,-0.015L0.011,-0.044C0.011,-0.053 0.015,-0.059 0.024,-0.063C0.042,-0.071 0.052,-0.084 0.052,-0.101L0.052,-0.69C0.052,-0.699 0.05,-0.704 0.048,-0.707C0.045,-0.71 0.037,-0.715 0.024,-0.721C0.018,-0.724 0.014,-0.727 0.013,-0.73C0.012,-0.734 0.011,-0.743 0.011,-0.757C0.011,-0.769 0.013,-0.778 0.016,-0.782C0.02,-0.786 0.026,-0.788 0.034,-0.788L0.302,-0.788C0.366,-0.788 0.416,-0.772 0.45,-0.739C0.485,-0.706 0.502,-0.664 0.502,-0.613C0.502,-0.571 0.488,-0.535 0.461,-0.507C0.434,-0.478 0.401,-0.459 0.361,-0.449ZM0.233,-0.733L0.233,-0.477L0.259,-0.477C0.276,-0.477 0.288,-0.481 0.298,-0.491C0.307,-0.5 0.312,-0.516 0.314,-0.538C0.315,-0.56 0.316,-0.588 0.316,-0.621C0.316,-0.668 0.311,-0.698 0.302,-0.712C0.294,-0.726 0.279,-0.733 0.259,-0.733L0.233,-0.733ZM0.233,-0.421L0.233,-0.056C0.263,-0.056 0.283,-0.06 0.294,-0.068C0.304,-0.076 0.31,-0.093 0.312,-0.117C0.314,-0.141 0.314,-0.181 0.314,-0.239C0.314,-0.25 0.314,-0.277 0.313,-0.319C0.312,-0.36 0.31,-0.385 0.307,-0.394C0.304,-0.402 0.299,-0.409 0.292,-0.414C0.284,-0.418 0.274,-0.421 0.259,-0.421L0.233,-0.421Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,592.762,58.1415)">
                <path d="M0.238,-0.728L0.238,-0.449L0.271,-0.449C0.281,-0.449 0.287,-0.451 0.292,-0.455C0.296,-0.459 0.299,-0.467 0.301,-0.48C0.305,-0.504 0.308,-0.517 0.311,-0.522C0.313,-0.527 0.317,-0.529 0.322,-0.529C0.348,-0.529 0.363,-0.528 0.366,-0.526C0.369,-0.524 0.371,-0.515 0.371,-0.501L0.371,-0.338C0.371,-0.328 0.369,-0.322 0.366,-0.319C0.363,-0.316 0.353,-0.314 0.336,-0.314C0.325,-0.314 0.317,-0.316 0.314,-0.32C0.31,-0.324 0.306,-0.336 0.303,-0.354C0.299,-0.372 0.296,-0.382 0.292,-0.386C0.289,-0.39 0.284,-0.392 0.276,-0.392L0.238,-0.392L0.238,-0.056L0.264,-0.056C0.28,-0.056 0.291,-0.06 0.297,-0.069C0.303,-0.078 0.313,-0.104 0.326,-0.146C0.332,-0.164 0.337,-0.176 0.343,-0.182C0.349,-0.187 0.359,-0.19 0.374,-0.19C0.394,-0.19 0.404,-0.183 0.404,-0.17C0.404,-0.167 0.404,-0.161 0.402,-0.153L0.384,-0.042C0.381,-0.022 0.378,-0.01 0.376,-0.006C0.374,-0.002 0.368,-0 0.356,-0L0.031,-0C0.023,-0 0.017,-0.002 0.015,-0.006C0.013,-0.009 0.012,-0.02 0.012,-0.038C0.012,-0.049 0.018,-0.058 0.029,-0.063C0.047,-0.07 0.056,-0.081 0.056,-0.094L0.056,-0.703C0.056,-0.709 0.049,-0.715 0.034,-0.721C0.024,-0.725 0.019,-0.729 0.017,-0.733C0.015,-0.737 0.014,-0.747 0.014,-0.762C0.014,-0.774 0.015,-0.782 0.017,-0.784C0.019,-0.787 0.025,-0.788 0.034,-0.788L0.346,-0.788C0.354,-0.788 0.359,-0.787 0.363,-0.784C0.366,-0.782 0.369,-0.775 0.371,-0.766L0.396,-0.64C0.397,-0.633 0.398,-0.629 0.398,-0.626C0.398,-0.618 0.39,-0.613 0.374,-0.613C0.357,-0.613 0.345,-0.615 0.339,-0.619C0.333,-0.623 0.327,-0.634 0.322,-0.65C0.312,-0.679 0.303,-0.7 0.296,-0.711C0.289,-0.722 0.28,-0.728 0.271,-0.728L0.238,-0.728Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,604.206,58.1415)">
                <path d="M0.136,-0.447L0.136,-0.102C0.136,-0.088 0.144,-0.077 0.16,-0.07C0.174,-0.064 0.181,-0.055 0.181,-0.043C0.181,-0.02 0.18,-0.007 0.179,-0.004C0.178,-0.001 0.173,-0 0.165,-0L0.035,-0C0.029,-0 0.025,-0.003 0.023,-0.008C0.021,-0.013 0.021,-0.025 0.021,-0.045C0.021,-0.054 0.025,-0.06 0.034,-0.064C0.05,-0.071 0.059,-0.08 0.059,-0.089L0.059,-0.667C0.059,-0.681 0.057,-0.691 0.055,-0.698C0.052,-0.705 0.046,-0.711 0.037,-0.715C0.029,-0.719 0.024,-0.723 0.021,-0.728C0.018,-0.733 0.017,-0.741 0.017,-0.754C0.017,-0.777 0.023,-0.788 0.035,-0.788L0.17,-0.788L0.334,-0.407L0.334,-0.688C0.334,-0.704 0.326,-0.715 0.311,-0.721C0.301,-0.725 0.295,-0.73 0.292,-0.736C0.289,-0.741 0.288,-0.751 0.288,-0.767C0.288,-0.777 0.289,-0.783 0.291,-0.785C0.293,-0.787 0.297,-0.788 0.302,-0.788L0.437,-0.788C0.444,-0.787 0.448,-0.776 0.448,-0.756C0.448,-0.744 0.448,-0.736 0.446,-0.733C0.445,-0.73 0.441,-0.727 0.436,-0.725C0.425,-0.72 0.417,-0.716 0.415,-0.711C0.412,-0.707 0.41,-0.701 0.41,-0.694L0.41,-0L0.327,-0L0.136,-0.447Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,616.803,58.1415)">
                <path d="M0.052,-0.108L0.052,-0.7C0.051,-0.706 0.045,-0.711 0.034,-0.714C0.026,-0.717 0.02,-0.721 0.017,-0.726C0.014,-0.731 0.013,-0.742 0.013,-0.758C0.013,-0.77 0.015,-0.778 0.019,-0.782C0.023,-0.786 0.034,-0.788 0.052,-0.788L0.286,-0.788C0.352,-0.788 0.408,-0.757 0.451,-0.696C0.495,-0.634 0.517,-0.532 0.517,-0.389C0.517,-0.259 0.496,-0.162 0.454,-0.097C0.411,-0.032 0.355,-0 0.286,-0L0.028,-0C0.021,-0 0.016,-0.002 0.013,-0.005C0.01,-0.009 0.009,-0.016 0.009,-0.028C0.009,-0.042 0.009,-0.051 0.01,-0.054C0.011,-0.058 0.016,-0.062 0.024,-0.066C0.043,-0.076 0.052,-0.09 0.052,-0.108ZM0.233,-0.73L0.233,-0.054L0.259,-0.054C0.278,-0.054 0.291,-0.061 0.299,-0.076C0.308,-0.092 0.312,-0.118 0.312,-0.157L0.312,-0.656C0.312,-0.678 0.307,-0.696 0.296,-0.709C0.286,-0.723 0.271,-0.73 0.252,-0.73L0.233,-0.73Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,641.091,58.1415)">
                <path d="M0.34,-0.801L0.365,-0.801C0.371,-0.801 0.375,-0.799 0.378,-0.795C0.381,-0.79 0.383,-0.778 0.385,-0.759L0.396,-0.602C0.396,-0.599 0.396,-0.596 0.396,-0.593C0.396,-0.581 0.395,-0.573 0.391,-0.569C0.387,-0.564 0.379,-0.562 0.367,-0.562C0.353,-0.562 0.343,-0.566 0.337,-0.573C0.332,-0.581 0.326,-0.598 0.321,-0.626C0.308,-0.697 0.289,-0.733 0.264,-0.733C0.244,-0.733 0.233,-0.707 0.233,-0.656L0.233,-0.107C0.233,-0.07 0.244,-0.052 0.266,-0.052C0.293,-0.052 0.308,-0.083 0.312,-0.144C0.313,-0.161 0.316,-0.173 0.322,-0.18C0.328,-0.187 0.34,-0.19 0.358,-0.19C0.372,-0.19 0.381,-0.188 0.386,-0.183C0.392,-0.178 0.395,-0.168 0.395,-0.153C0.395,-0.107 0.381,-0.068 0.353,-0.037C0.325,-0.007 0.29,0.009 0.248,0.009C0.208,0.009 0.172,-0.004 0.142,-0.03C0.111,-0.056 0.085,-0.1 0.062,-0.163C0.04,-0.226 0.029,-0.306 0.029,-0.405C0.029,-0.498 0.038,-0.572 0.057,-0.627C0.076,-0.683 0.1,-0.726 0.129,-0.756C0.158,-0.786 0.192,-0.801 0.23,-0.801C0.254,-0.801 0.274,-0.795 0.292,-0.782C0.298,-0.778 0.303,-0.775 0.306,-0.775C0.31,-0.775 0.316,-0.78 0.323,-0.789C0.329,-0.797 0.335,-0.801 0.34,-0.801Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,652.253,58.1415)">
                <path d="M0.271,-0.801C0.319,-0.801 0.361,-0.785 0.396,-0.753C0.431,-0.721 0.459,-0.675 0.481,-0.616C0.504,-0.557 0.515,-0.481 0.515,-0.388C0.515,-0.277 0.493,-0.183 0.45,-0.106C0.407,-0.03 0.346,0.009 0.268,0.009C0.193,0.009 0.134,-0.029 0.091,-0.104C0.048,-0.178 0.027,-0.275 0.027,-0.394C0.027,-0.515 0.049,-0.613 0.093,-0.688C0.137,-0.764 0.197,-0.801 0.271,-0.801ZM0.31,-0.686C0.31,-0.726 0.297,-0.747 0.271,-0.747C0.259,-0.747 0.25,-0.742 0.242,-0.731C0.234,-0.721 0.23,-0.709 0.23,-0.694L0.23,-0.105C0.23,-0.085 0.234,-0.07 0.242,-0.06C0.25,-0.05 0.259,-0.044 0.27,-0.044C0.282,-0.044 0.292,-0.05 0.299,-0.062C0.306,-0.073 0.31,-0.086 0.31,-0.1L0.31,-0.686Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,667.131,58.1415)">
                <path d="M0.035,-0.788L0.246,-0.788C0.253,-0.788 0.258,-0.787 0.26,-0.783C0.262,-0.78 0.263,-0.771 0.263,-0.755C0.263,-0.739 0.258,-0.728 0.249,-0.723C0.241,-0.719 0.237,-0.715 0.234,-0.711C0.232,-0.707 0.23,-0.699 0.23,-0.686L0.23,-0.139C0.23,-0.124 0.235,-0.112 0.243,-0.103C0.252,-0.094 0.262,-0.089 0.275,-0.089C0.287,-0.089 0.298,-0.094 0.306,-0.104C0.315,-0.115 0.319,-0.126 0.319,-0.139L0.319,-0.686C0.319,-0.695 0.318,-0.702 0.316,-0.706C0.314,-0.71 0.31,-0.714 0.303,-0.72C0.292,-0.728 0.286,-0.741 0.286,-0.759C0.286,-0.773 0.288,-0.781 0.291,-0.784C0.294,-0.787 0.298,-0.788 0.304,-0.788L0.41,-0.788C0.421,-0.788 0.427,-0.782 0.427,-0.771L0.427,-0.747C0.427,-0.737 0.427,-0.73 0.425,-0.727C0.424,-0.725 0.42,-0.722 0.414,-0.72C0.399,-0.715 0.391,-0.704 0.391,-0.688L0.391,-0.155C0.391,-0.105 0.377,-0.065 0.348,-0.035C0.319,-0.006 0.278,0.009 0.227,0.009C0.169,0.009 0.124,-0.007 0.094,-0.038C0.064,-0.069 0.048,-0.114 0.048,-0.173L0.048,-0.688C0.048,-0.702 0.041,-0.712 0.027,-0.718C0.02,-0.722 0.015,-0.725 0.014,-0.727C0.012,-0.73 0.012,-0.736 0.012,-0.745C0.012,-0.764 0.013,-0.776 0.015,-0.781C0.017,-0.786 0.024,-0.788 0.035,-0.788Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,679.151,58.1415)">
                <path d="M0.136,-0.447L0.136,-0.102C0.136,-0.088 0.144,-0.077 0.16,-0.07C0.174,-0.064 0.181,-0.055 0.181,-0.043C0.181,-0.02 0.18,-0.007 0.179,-0.004C0.178,-0.001 0.173,-0 0.165,-0L0.035,-0C0.029,-0 0.025,-0.003 0.023,-0.008C0.021,-0.013 0.021,-0.025 0.021,-0.045C0.021,-0.054 0.025,-0.06 0.034,-0.064C0.05,-0.071 0.059,-0.08 0.059,-0.089L0.059,-0.667C0.059,-0.681 0.057,-0.691 0.055,-0.698C0.052,-0.705 0.046,-0.711 0.037,-0.715C0.029,-0.719 0.024,-0.723 0.021,-0.728C0.018,-0.733 0.017,-0.741 0.017,-0.754C0.017,-0.777 0.023,-0.788 0.035,-0.788L0.17,-0.788L0.334,-0.407L0.334,-0.688C0.334,-0.704 0.326,-0.715 0.311,-0.721C0.301,-0.725 0.295,-0.73 0.292,-0.736C0.289,-0.741 0.288,-0.751 0.288,-0.767C0.288,-0.777 0.289,-0.783 0.291,-0.785C0.293,-0.787 0.297,-0.788 0.302,-0.788L0.437,-0.788C0.444,-0.787 0.448,-0.776 0.448,-0.756C0.448,-0.744 0.448,-0.736 0.446,-0.733C0.445,-0.73 0.441,-0.727 0.436,-0.725C0.425,-0.72 0.417,-0.716 0.415,-0.711C0.412,-0.707 0.41,-0.701 0.41,-0.694L0.41,-0L0.327,-0L0.136,-0.447Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,691.748,58.1415)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,704.628,58.1415)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,712.65,58.1415)">
                <path d="M0.238,-0.728L0.238,-0.449L0.271,-0.449C0.281,-0.449 0.287,-0.451 0.292,-0.455C0.296,-0.459 0.299,-0.467 0.301,-0.48C0.305,-0.504 0.308,-0.517 0.311,-0.522C0.313,-0.527 0.317,-0.529 0.322,-0.529C0.348,-0.529 0.363,-0.528 0.366,-0.526C0.369,-0.524 0.371,-0.515 0.371,-0.501L0.371,-0.338C0.371,-0.328 0.369,-0.322 0.366,-0.319C0.363,-0.316 0.353,-0.314 0.336,-0.314C0.325,-0.314 0.317,-0.316 0.314,-0.32C0.31,-0.324 0.306,-0.336 0.303,-0.354C0.299,-0.372 0.296,-0.382 0.292,-0.386C0.289,-0.39 0.284,-0.392 0.276,-0.392L0.238,-0.392L0.238,-0.056L0.264,-0.056C0.28,-0.056 0.291,-0.06 0.297,-0.069C0.303,-0.078 0.313,-0.104 0.326,-0.146C0.332,-0.164 0.337,-0.176 0.343,-0.182C0.349,-0.187 0.359,-0.19 0.374,-0.19C0.394,-0.19 0.404,-0.183 0.404,-0.17C0.404,-0.167 0.404,-0.161 0.402,-0.153L0.384,-0.042C0.381,-0.022 0.378,-0.01 0.376,-0.006C0.374,-0.002 0.368,-0 0.356,-0L0.031,-0C0.023,-0 0.017,-0.002 0.015,-0.006C0.013,-0.009 0.012,-0.02 0.012,-0.038C0.012,-0.049 0.018,-0.058 0.029,-0.063C0.047,-0.07 0.056,-0.081 0.056,-0.094L0.056,-0.703C0.056,-0.709 0.049,-0.715 0.034,-0.721C0.024,-0.725 0.019,-0.729 0.017,-0.733C0.015,-0.737 0.014,-0.747 0.014,-0.762C0.014,-0.774 0.015,-0.782 0.017,-0.784C0.019,-0.787 0.025,-0.788 0.034,-0.788L0.346,-0.788C0.354,-0.788 0.359,-0.787 0.363,-0.784C0.366,-0.782 0.369,-0.775 0.371,-0.766L0.396,-0.64C0.397,-0.633 0.398,-0.629 0.398,-0.626C0.398,-0.618 0.39,-0.613 0.374,-0.613C0.357,-0.613 0.345,-0.615 0.339,-0.619C0.333,-0.623 0.327,-0.634 0.322,-0.65C0.312,-0.679 0.303,-0.7 0.296,-0.711C0.289,-0.722 0.28,-0.728 0.271,-0.728L0.238,-0.728Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,724.094,58.1415)">
                <path d="M0.243,-0.767C0.245,-0.769 0.251,-0.776 0.26,-0.789C0.266,-0.798 0.273,-0.802 0.283,-0.802C0.285,-0.802 0.295,-0.801 0.311,-0.798C0.314,-0.795 0.316,-0.788 0.318,-0.778L0.344,-0.612C0.346,-0.604 0.346,-0.598 0.346,-0.597C0.346,-0.59 0.344,-0.586 0.341,-0.584C0.338,-0.583 0.329,-0.582 0.314,-0.582C0.297,-0.582 0.287,-0.584 0.282,-0.588C0.277,-0.592 0.268,-0.61 0.254,-0.641C0.225,-0.706 0.199,-0.739 0.176,-0.739C0.16,-0.739 0.152,-0.727 0.152,-0.705C0.152,-0.676 0.176,-0.621 0.226,-0.538C0.282,-0.442 0.319,-0.373 0.336,-0.33C0.353,-0.287 0.361,-0.239 0.361,-0.186C0.361,-0.123 0.347,-0.075 0.317,-0.042C0.287,-0.008 0.254,0.009 0.217,0.009C0.193,0.009 0.171,0.002 0.149,-0.011C0.14,-0.016 0.134,-0.019 0.129,-0.019C0.125,-0.019 0.121,-0.016 0.119,-0.01C0.113,0.003 0.101,0.009 0.081,0.009C0.073,0.009 0.067,0.008 0.065,0.005C0.062,0.003 0.06,-0.004 0.059,-0.014L0.034,-0.177C0.031,-0.198 0.029,-0.209 0.029,-0.21C0.029,-0.219 0.038,-0.224 0.055,-0.224C0.079,-0.224 0.092,-0.221 0.097,-0.215C0.101,-0.209 0.107,-0.194 0.115,-0.172C0.143,-0.093 0.172,-0.054 0.201,-0.054C0.212,-0.054 0.221,-0.058 0.227,-0.065C0.234,-0.073 0.237,-0.083 0.237,-0.095C0.237,-0.115 0.23,-0.14 0.215,-0.168C0.2,-0.196 0.182,-0.227 0.161,-0.262C0.109,-0.344 0.073,-0.41 0.052,-0.46C0.03,-0.509 0.02,-0.559 0.02,-0.611C0.02,-0.669 0.032,-0.715 0.058,-0.75C0.083,-0.784 0.114,-0.801 0.151,-0.801C0.176,-0.801 0.207,-0.79 0.243,-0.767Z" />
            </g>
        </g>
        <g transform="matrix(1.21576,0,0,1.21576,-92.1571,-35.2454)">
            <g transform="matrix(27.4754,0,0,27.4754,401.4,99.8802)">
                <path d="M0.374,-0.788L0.547,-0.788C0.559,-0.788 0.566,-0.786 0.57,-0.783C0.573,-0.78 0.575,-0.771 0.575,-0.757C0.575,-0.744 0.574,-0.735 0.572,-0.73C0.569,-0.725 0.563,-0.72 0.554,-0.716C0.545,-0.712 0.54,-0.708 0.538,-0.703C0.535,-0.699 0.534,-0.691 0.534,-0.681L0.534,-0.101C0.534,-0.093 0.535,-0.086 0.537,-0.083C0.539,-0.079 0.544,-0.075 0.553,-0.07C0.565,-0.064 0.571,-0.056 0.571,-0.046C0.571,-0.021 0.57,-0.007 0.569,-0.004C0.567,-0.001 0.562,-0 0.552,-0L0.334,-0C0.325,-0 0.32,-0.008 0.32,-0.024C0.32,-0.038 0.32,-0.048 0.321,-0.054C0.322,-0.061 0.328,-0.067 0.339,-0.071C0.352,-0.076 0.358,-0.084 0.358,-0.097L0.358,-0.508L0.27,-0.176L0.216,-0.176L0.13,-0.498L0.13,-0.098C0.13,-0.085 0.136,-0.076 0.147,-0.069C0.162,-0.062 0.169,-0.049 0.169,-0.031C0.169,-0.01 0.162,-0 0.147,-0L0.042,-0C0.029,-0 0.023,-0.01 0.023,-0.03C0.023,-0.044 0.028,-0.055 0.039,-0.062C0.046,-0.068 0.051,-0.073 0.053,-0.077C0.055,-0.081 0.056,-0.087 0.056,-0.097L0.056,-0.661C0.056,-0.676 0.054,-0.688 0.052,-0.697C0.049,-0.705 0.043,-0.712 0.034,-0.717C0.024,-0.722 0.019,-0.727 0.019,-0.733L0.02,-0.754C0.02,-0.769 0.021,-0.779 0.023,-0.782C0.024,-0.786 0.029,-0.788 0.036,-0.788L0.216,-0.788L0.294,-0.486L0.374,-0.788Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,417.432,99.8802)">
                <path d="M0.035,-0.788L0.246,-0.788C0.253,-0.788 0.258,-0.787 0.26,-0.783C0.262,-0.78 0.263,-0.771 0.263,-0.755C0.263,-0.739 0.258,-0.728 0.249,-0.723C0.241,-0.719 0.237,-0.715 0.234,-0.711C0.232,-0.707 0.23,-0.699 0.23,-0.686L0.23,-0.139C0.23,-0.124 0.235,-0.112 0.243,-0.103C0.252,-0.094 0.262,-0.089 0.275,-0.089C0.287,-0.089 0.298,-0.094 0.306,-0.104C0.315,-0.115 0.319,-0.126 0.319,-0.139L0.319,-0.686C0.319,-0.695 0.318,-0.702 0.316,-0.706C0.314,-0.71 0.31,-0.714 0.303,-0.72C0.292,-0.728 0.286,-0.741 0.286,-0.759C0.286,-0.773 0.288,-0.781 0.291,-0.784C0.294,-0.787 0.298,-0.788 0.304,-0.788L0.41,-0.788C0.421,-0.788 0.427,-0.782 0.427,-0.771L0.427,-0.747C0.427,-0.737 0.427,-0.73 0.425,-0.727C0.424,-0.725 0.42,-0.722 0.414,-0.72C0.399,-0.715 0.391,-0.704 0.391,-0.688L0.391,-0.155C0.391,-0.105 0.377,-0.065 0.348,-0.035C0.319,-0.006 0.278,0.009 0.227,0.009C0.169,0.009 0.124,-0.007 0.094,-0.038C0.064,-0.069 0.048,-0.114 0.048,-0.173L0.048,-0.688C0.048,-0.702 0.041,-0.712 0.027,-0.718C0.02,-0.722 0.015,-0.725 0.014,-0.727C0.012,-0.73 0.012,-0.736 0.012,-0.745C0.012,-0.764 0.013,-0.776 0.015,-0.781C0.017,-0.786 0.024,-0.788 0.035,-0.788Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,429.453,99.8802)">
                <path d="M0.136,-0.447L0.136,-0.102C0.136,-0.088 0.144,-0.077 0.16,-0.07C0.174,-0.064 0.181,-0.055 0.181,-0.043C0.181,-0.02 0.18,-0.007 0.179,-0.004C0.178,-0.001 0.173,-0 0.165,-0L0.035,-0C0.029,-0 0.025,-0.003 0.023,-0.008C0.021,-0.013 0.021,-0.025 0.021,-0.045C0.021,-0.054 0.025,-0.06 0.034,-0.064C0.05,-0.071 0.059,-0.08 0.059,-0.089L0.059,-0.667C0.059,-0.681 0.057,-0.691 0.055,-0.698C0.052,-0.705 0.046,-0.711 0.037,-0.715C0.029,-0.719 0.024,-0.723 0.021,-0.728C0.018,-0.733 0.017,-0.741 0.017,-0.754C0.017,-0.777 0.023,-0.788 0.035,-0.788L0.17,-0.788L0.334,-0.407L0.334,-0.688C0.334,-0.704 0.326,-0.715 0.311,-0.721C0.301,-0.725 0.295,-0.73 0.292,-0.736C0.289,-0.741 0.288,-0.751 0.288,-0.767C0.288,-0.777 0.289,-0.783 0.291,-0.785C0.293,-0.787 0.297,-0.788 0.302,-0.788L0.437,-0.788C0.444,-0.787 0.448,-0.776 0.448,-0.756C0.448,-0.744 0.448,-0.736 0.446,-0.733C0.445,-0.73 0.441,-0.727 0.436,-0.725C0.425,-0.72 0.417,-0.716 0.415,-0.711C0.412,-0.707 0.41,-0.701 0.41,-0.694L0.41,-0L0.327,-0L0.136,-0.447Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,442.05,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,450.073,99.8802)">
                <path d="M0.34,-0.801L0.365,-0.801C0.371,-0.801 0.375,-0.799 0.378,-0.795C0.381,-0.79 0.383,-0.778 0.385,-0.759L0.396,-0.602C0.396,-0.599 0.396,-0.596 0.396,-0.593C0.396,-0.581 0.395,-0.573 0.391,-0.569C0.387,-0.564 0.379,-0.562 0.367,-0.562C0.353,-0.562 0.343,-0.566 0.337,-0.573C0.332,-0.581 0.326,-0.598 0.321,-0.626C0.308,-0.697 0.289,-0.733 0.264,-0.733C0.244,-0.733 0.233,-0.707 0.233,-0.656L0.233,-0.107C0.233,-0.07 0.244,-0.052 0.266,-0.052C0.293,-0.052 0.308,-0.083 0.312,-0.144C0.313,-0.161 0.316,-0.173 0.322,-0.18C0.328,-0.187 0.34,-0.19 0.358,-0.19C0.372,-0.19 0.381,-0.188 0.386,-0.183C0.392,-0.178 0.395,-0.168 0.395,-0.153C0.395,-0.107 0.381,-0.068 0.353,-0.037C0.325,-0.007 0.29,0.009 0.248,0.009C0.208,0.009 0.172,-0.004 0.142,-0.03C0.111,-0.056 0.085,-0.1 0.062,-0.163C0.04,-0.226 0.029,-0.306 0.029,-0.405C0.029,-0.498 0.038,-0.572 0.057,-0.627C0.076,-0.683 0.1,-0.726 0.129,-0.756C0.158,-0.786 0.192,-0.801 0.23,-0.801C0.254,-0.801 0.274,-0.795 0.292,-0.782C0.298,-0.778 0.303,-0.775 0.306,-0.775C0.31,-0.775 0.316,-0.78 0.323,-0.789C0.329,-0.797 0.335,-0.801 0.34,-0.801Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,461.234,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,469.257,99.8802)">
                <path d="M0.239,-0.269L0.239,-0.106C0.239,-0.087 0.247,-0.075 0.263,-0.069C0.271,-0.066 0.276,-0.062 0.28,-0.057C0.283,-0.052 0.284,-0.042 0.284,-0.029C0.284,-0.016 0.282,-0.008 0.279,-0.005C0.275,-0.002 0.268,-0 0.257,-0L0.036,-0C0.024,-0 0.019,-0.01 0.019,-0.031C0.019,-0.047 0.019,-0.057 0.021,-0.061C0.023,-0.065 0.028,-0.068 0.038,-0.071C0.046,-0.073 0.051,-0.076 0.053,-0.079C0.056,-0.082 0.057,-0.088 0.057,-0.095L0.057,-0.685C0.057,-0.699 0.048,-0.71 0.031,-0.719C0.022,-0.724 0.017,-0.731 0.017,-0.741L0.016,-0.764C0.016,-0.774 0.017,-0.78 0.02,-0.783C0.022,-0.787 0.028,-0.788 0.036,-0.788L0.286,-0.788C0.358,-0.788 0.415,-0.765 0.458,-0.719C0.501,-0.673 0.522,-0.609 0.522,-0.528C0.522,-0.482 0.516,-0.441 0.503,-0.405C0.49,-0.369 0.473,-0.343 0.453,-0.326C0.433,-0.309 0.41,-0.295 0.384,-0.285C0.357,-0.274 0.329,-0.269 0.3,-0.269L0.239,-0.269ZM0.239,-0.734L0.239,-0.325L0.273,-0.325C0.285,-0.325 0.295,-0.331 0.304,-0.344C0.314,-0.357 0.318,-0.378 0.318,-0.408L0.318,-0.652C0.318,-0.707 0.3,-0.734 0.264,-0.734L0.239,-0.734Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,482.485,99.8802)">
                <path d="M0.248,-0.168L0.127,-0.168L0.121,-0.124C0.121,-0.123 0.121,-0.121 0.12,-0.119C0.117,-0.11 0.116,-0.101 0.116,-0.094C0.116,-0.082 0.124,-0.072 0.139,-0.064C0.147,-0.06 0.152,-0.055 0.154,-0.051C0.156,-0.046 0.157,-0.039 0.157,-0.028C0.157,-0.015 0.156,-0.007 0.155,-0.004C0.153,-0.001 0.15,-0 0.144,-0L0.016,-0C0.008,-0 0.005,-0.007 0.005,-0.022C0.005,-0.04 0.011,-0.055 0.022,-0.066C0.033,-0.076 0.039,-0.087 0.041,-0.098L0.131,-0.673C0.133,-0.682 0.133,-0.689 0.133,-0.696C0.133,-0.706 0.126,-0.715 0.112,-0.723C0.106,-0.727 0.103,-0.735 0.103,-0.749C0.103,-0.766 0.104,-0.778 0.105,-0.782C0.107,-0.786 0.113,-0.788 0.121,-0.788L0.308,-0.788C0.319,-0.788 0.327,-0.783 0.331,-0.772C0.336,-0.761 0.341,-0.738 0.345,-0.702C0.347,-0.688 0.349,-0.674 0.351,-0.66L0.431,-0.124C0.435,-0.097 0.445,-0.078 0.46,-0.064C0.464,-0.06 0.467,-0.058 0.468,-0.055C0.47,-0.053 0.47,-0.047 0.47,-0.036C0.47,-0.02 0.469,-0.009 0.467,-0.006C0.465,-0.002 0.46,-0 0.452,-0L0.235,-0C0.229,-0 0.226,-0.006 0.226,-0.019C0.226,-0.034 0.226,-0.044 0.228,-0.048C0.23,-0.052 0.234,-0.056 0.242,-0.062C0.253,-0.069 0.259,-0.078 0.259,-0.089C0.259,-0.1 0.256,-0.127 0.248,-0.168ZM0.19,-0.606L0.135,-0.225L0.243,-0.225L0.19,-0.606Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,495.646,99.8802)">
                <path d="M0.236,-0.055L0.264,-0.055C0.278,-0.055 0.289,-0.061 0.296,-0.072C0.303,-0.084 0.312,-0.11 0.323,-0.151C0.327,-0.167 0.331,-0.177 0.337,-0.18C0.342,-0.184 0.352,-0.186 0.365,-0.186C0.38,-0.186 0.389,-0.184 0.394,-0.182C0.398,-0.179 0.4,-0.175 0.4,-0.168C0.4,-0.164 0.399,-0.157 0.397,-0.146L0.38,-0.03C0.378,-0.018 0.376,-0.01 0.373,-0.006C0.37,-0.002 0.363,-0 0.354,-0L0.033,-0C0.019,-0 0.012,-0.008 0.012,-0.025C0.012,-0.038 0.013,-0.047 0.015,-0.051C0.017,-0.054 0.021,-0.057 0.026,-0.059C0.045,-0.065 0.054,-0.082 0.054,-0.11L0.054,-0.69C0.054,-0.702 0.048,-0.711 0.037,-0.716C0.027,-0.721 0.02,-0.725 0.018,-0.73C0.016,-0.736 0.015,-0.744 0.015,-0.756C0.015,-0.771 0.016,-0.78 0.018,-0.783C0.02,-0.786 0.025,-0.788 0.033,-0.788L0.258,-0.788C0.267,-0.788 0.273,-0.786 0.276,-0.782C0.279,-0.778 0.28,-0.767 0.28,-0.749C0.28,-0.738 0.279,-0.731 0.277,-0.727C0.275,-0.724 0.27,-0.72 0.261,-0.717C0.244,-0.712 0.236,-0.701 0.236,-0.685L0.236,-0.055Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,516.217,99.8802)">
                <path d="M0.035,-0.788L0.246,-0.788C0.253,-0.788 0.258,-0.787 0.26,-0.783C0.262,-0.78 0.263,-0.771 0.263,-0.755C0.263,-0.739 0.258,-0.728 0.249,-0.723C0.241,-0.719 0.237,-0.715 0.234,-0.711C0.232,-0.707 0.23,-0.699 0.23,-0.686L0.23,-0.139C0.23,-0.124 0.235,-0.112 0.243,-0.103C0.252,-0.094 0.262,-0.089 0.275,-0.089C0.287,-0.089 0.298,-0.094 0.306,-0.104C0.315,-0.115 0.319,-0.126 0.319,-0.139L0.319,-0.686C0.319,-0.695 0.318,-0.702 0.316,-0.706C0.314,-0.71 0.31,-0.714 0.303,-0.72C0.292,-0.728 0.286,-0.741 0.286,-0.759C0.286,-0.773 0.288,-0.781 0.291,-0.784C0.294,-0.787 0.298,-0.788 0.304,-0.788L0.41,-0.788C0.421,-0.788 0.427,-0.782 0.427,-0.771L0.427,-0.747C0.427,-0.737 0.427,-0.73 0.425,-0.727C0.424,-0.725 0.42,-0.722 0.414,-0.72C0.399,-0.715 0.391,-0.704 0.391,-0.688L0.391,-0.155C0.391,-0.105 0.377,-0.065 0.348,-0.035C0.319,-0.006 0.278,0.009 0.227,0.009C0.169,0.009 0.124,-0.007 0.094,-0.038C0.064,-0.069 0.048,-0.114 0.048,-0.173L0.048,-0.688C0.048,-0.702 0.041,-0.712 0.027,-0.718C0.02,-0.722 0.015,-0.725 0.014,-0.727C0.012,-0.73 0.012,-0.736 0.012,-0.745C0.012,-0.764 0.013,-0.776 0.015,-0.781C0.017,-0.786 0.024,-0.788 0.035,-0.788Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,528.238,99.8802)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,541.117,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,549.139,99.8802)">
                <path d="M0.236,-0.055L0.264,-0.055C0.278,-0.055 0.289,-0.061 0.296,-0.072C0.303,-0.084 0.312,-0.11 0.323,-0.151C0.327,-0.167 0.331,-0.177 0.337,-0.18C0.342,-0.184 0.352,-0.186 0.365,-0.186C0.38,-0.186 0.389,-0.184 0.394,-0.182C0.398,-0.179 0.4,-0.175 0.4,-0.168C0.4,-0.164 0.399,-0.157 0.397,-0.146L0.38,-0.03C0.378,-0.018 0.376,-0.01 0.373,-0.006C0.37,-0.002 0.363,-0 0.354,-0L0.033,-0C0.019,-0 0.012,-0.008 0.012,-0.025C0.012,-0.038 0.013,-0.047 0.015,-0.051C0.017,-0.054 0.021,-0.057 0.026,-0.059C0.045,-0.065 0.054,-0.082 0.054,-0.11L0.054,-0.69C0.054,-0.702 0.048,-0.711 0.037,-0.716C0.027,-0.721 0.02,-0.725 0.018,-0.73C0.016,-0.736 0.015,-0.744 0.015,-0.756C0.015,-0.771 0.016,-0.78 0.018,-0.783C0.02,-0.786 0.025,-0.788 0.033,-0.788L0.258,-0.788C0.267,-0.788 0.273,-0.786 0.276,-0.782C0.279,-0.778 0.28,-0.767 0.28,-0.749C0.28,-0.738 0.279,-0.731 0.277,-0.727C0.275,-0.724 0.27,-0.72 0.261,-0.717C0.244,-0.712 0.236,-0.701 0.236,-0.685L0.236,-0.055Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,560.301,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,568.324,99.8802)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,581.203,99.8802)">
                <path d="M0.334,-0.353L0.334,-0.097C0.334,-0.084 0.342,-0.075 0.359,-0.069C0.366,-0.066 0.371,-0.064 0.374,-0.061C0.377,-0.058 0.378,-0.047 0.378,-0.026C0.378,-0.014 0.377,-0.007 0.374,-0.004C0.371,-0.001 0.366,-0 0.358,-0L0.127,-0C0.116,-0 0.11,-0.005 0.11,-0.015L0.11,-0.044C0.11,-0.056 0.117,-0.064 0.132,-0.069C0.14,-0.072 0.145,-0.075 0.148,-0.079C0.151,-0.083 0.152,-0.09 0.152,-0.1L0.152,-0.34L0.054,-0.688C0.049,-0.705 0.04,-0.716 0.026,-0.722C0.019,-0.725 0.016,-0.73 0.016,-0.738L0.016,-0.775C0.016,-0.784 0.02,-0.788 0.03,-0.788L0.237,-0.788C0.243,-0.788 0.246,-0.786 0.248,-0.783C0.25,-0.78 0.251,-0.771 0.251,-0.757C0.251,-0.743 0.251,-0.734 0.25,-0.731C0.248,-0.727 0.245,-0.724 0.24,-0.721C0.233,-0.716 0.229,-0.71 0.229,-0.702C0.229,-0.698 0.23,-0.693 0.231,-0.688L0.295,-0.47L0.361,-0.688C0.361,-0.69 0.362,-0.693 0.362,-0.697C0.362,-0.707 0.355,-0.718 0.342,-0.729C0.336,-0.734 0.333,-0.739 0.333,-0.743L0.333,-0.772C0.333,-0.783 0.338,-0.788 0.348,-0.788L0.455,-0.788C0.465,-0.788 0.47,-0.783 0.47,-0.772L0.47,-0.741C0.47,-0.736 0.466,-0.73 0.459,-0.725C0.447,-0.716 0.438,-0.704 0.433,-0.688L0.334,-0.353Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,603.774,99.8802)">
                <path d="M0.052,-0.108L0.052,-0.7C0.051,-0.706 0.045,-0.711 0.034,-0.714C0.026,-0.717 0.02,-0.721 0.017,-0.726C0.014,-0.731 0.013,-0.742 0.013,-0.758C0.013,-0.77 0.015,-0.778 0.019,-0.782C0.023,-0.786 0.034,-0.788 0.052,-0.788L0.286,-0.788C0.352,-0.788 0.408,-0.757 0.451,-0.696C0.495,-0.634 0.517,-0.532 0.517,-0.389C0.517,-0.259 0.496,-0.162 0.454,-0.097C0.411,-0.032 0.355,-0 0.286,-0L0.028,-0C0.021,-0 0.016,-0.002 0.013,-0.005C0.01,-0.009 0.009,-0.016 0.009,-0.028C0.009,-0.042 0.009,-0.051 0.01,-0.054C0.011,-0.058 0.016,-0.062 0.024,-0.066C0.043,-0.076 0.052,-0.09 0.052,-0.108ZM0.233,-0.73L0.233,-0.054L0.259,-0.054C0.278,-0.054 0.291,-0.061 0.299,-0.076C0.308,-0.092 0.312,-0.118 0.312,-0.157L0.312,-0.656C0.312,-0.678 0.307,-0.696 0.296,-0.709C0.286,-0.723 0.271,-0.73 0.252,-0.73L0.233,-0.73Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,618.652,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,626.674,99.8802)">
                <path d="M0.243,-0.767C0.245,-0.769 0.251,-0.776 0.26,-0.789C0.266,-0.798 0.273,-0.802 0.283,-0.802C0.285,-0.802 0.295,-0.801 0.311,-0.798C0.314,-0.795 0.316,-0.788 0.318,-0.778L0.344,-0.612C0.346,-0.604 0.346,-0.598 0.346,-0.597C0.346,-0.59 0.344,-0.586 0.341,-0.584C0.338,-0.583 0.329,-0.582 0.314,-0.582C0.297,-0.582 0.287,-0.584 0.282,-0.588C0.277,-0.592 0.268,-0.61 0.254,-0.641C0.225,-0.706 0.199,-0.739 0.176,-0.739C0.16,-0.739 0.152,-0.727 0.152,-0.705C0.152,-0.676 0.176,-0.621 0.226,-0.538C0.282,-0.442 0.319,-0.373 0.336,-0.33C0.353,-0.287 0.361,-0.239 0.361,-0.186C0.361,-0.123 0.347,-0.075 0.317,-0.042C0.287,-0.008 0.254,0.009 0.217,0.009C0.193,0.009 0.171,0.002 0.149,-0.011C0.14,-0.016 0.134,-0.019 0.129,-0.019C0.125,-0.019 0.121,-0.016 0.119,-0.01C0.113,0.003 0.101,0.009 0.081,0.009C0.073,0.009 0.067,0.008 0.065,0.005C0.062,0.003 0.06,-0.004 0.059,-0.014L0.034,-0.177C0.031,-0.198 0.029,-0.209 0.029,-0.21C0.029,-0.219 0.038,-0.224 0.055,-0.224C0.079,-0.224 0.092,-0.221 0.097,-0.215C0.101,-0.209 0.107,-0.194 0.115,-0.172C0.143,-0.093 0.172,-0.054 0.201,-0.054C0.212,-0.054 0.221,-0.058 0.227,-0.065C0.234,-0.073 0.237,-0.083 0.237,-0.095C0.237,-0.115 0.23,-0.14 0.215,-0.168C0.2,-0.196 0.182,-0.227 0.161,-0.262C0.109,-0.344 0.073,-0.41 0.052,-0.46C0.03,-0.509 0.02,-0.559 0.02,-0.611C0.02,-0.669 0.032,-0.715 0.058,-0.75C0.083,-0.784 0.114,-0.801 0.151,-0.801C0.176,-0.801 0.207,-0.79 0.243,-0.767Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,636.977,99.8802)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,649.857,99.8802)">
                <path d="M0.24,-0.347L0.24,-0.104C0.24,-0.093 0.241,-0.085 0.242,-0.082C0.244,-0.078 0.248,-0.074 0.255,-0.069C0.263,-0.064 0.268,-0.055 0.268,-0.042C0.268,-0.023 0.266,-0.011 0.262,-0.007C0.259,-0.002 0.253,-0 0.244,-0L0.038,-0C0.026,-0 0.02,-0.008 0.02,-0.025C0.02,-0.042 0.021,-0.052 0.021,-0.057C0.022,-0.062 0.026,-0.065 0.031,-0.067C0.049,-0.074 0.058,-0.088 0.058,-0.109L0.058,-0.681C0.058,-0.702 0.05,-0.715 0.035,-0.72C0.027,-0.722 0.022,-0.725 0.02,-0.727C0.018,-0.73 0.017,-0.738 0.017,-0.75C0.017,-0.775 0.024,-0.788 0.038,-0.788L0.308,-0.788C0.361,-0.788 0.409,-0.768 0.451,-0.728C0.494,-0.687 0.515,-0.633 0.515,-0.565C0.515,-0.468 0.465,-0.405 0.367,-0.375C0.457,-0.356 0.502,-0.299 0.502,-0.204L0.502,-0.088C0.502,-0.078 0.506,-0.071 0.513,-0.066C0.522,-0.06 0.526,-0.051 0.526,-0.039C0.526,-0.02 0.525,-0.009 0.523,-0.005C0.521,-0.002 0.515,-0 0.505,-0L0.374,-0C0.358,-0 0.346,-0.009 0.335,-0.027C0.325,-0.044 0.32,-0.066 0.32,-0.092L0.32,-0.289C0.32,-0.327 0.301,-0.347 0.262,-0.347L0.24,-0.347ZM0.24,-0.732L0.24,-0.403L0.272,-0.403C0.304,-0.403 0.32,-0.436 0.32,-0.5L0.32,-0.658C0.32,-0.685 0.315,-0.704 0.305,-0.716C0.295,-0.727 0.279,-0.732 0.257,-0.732L0.24,-0.732Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,664.735,99.8802)">
                <path d="M0.015,-0.747L0.015,-0.773C0.015,-0.783 0.02,-0.788 0.031,-0.788L0.265,-0.788C0.273,-0.788 0.279,-0.787 0.282,-0.785C0.284,-0.782 0.286,-0.776 0.286,-0.764C0.286,-0.747 0.285,-0.736 0.283,-0.732C0.281,-0.728 0.277,-0.725 0.271,-0.724C0.251,-0.721 0.241,-0.708 0.241,-0.686L0.241,-0.099C0.241,-0.086 0.248,-0.076 0.263,-0.069C0.272,-0.065 0.278,-0.061 0.28,-0.057C0.282,-0.054 0.283,-0.047 0.283,-0.039L0.283,-0.02C0.283,-0.009 0.282,-0.003 0.28,-0.002C0.278,-0.001 0.273,-0 0.265,-0L0.026,-0C0.017,-0 0.013,-0.004 0.013,-0.011C0.013,-0.034 0.014,-0.049 0.015,-0.054C0.017,-0.059 0.022,-0.063 0.032,-0.066C0.05,-0.071 0.059,-0.08 0.059,-0.094L0.059,-0.673C0.059,-0.687 0.057,-0.697 0.054,-0.704C0.05,-0.711 0.043,-0.717 0.032,-0.722C0.024,-0.725 0.019,-0.728 0.017,-0.731C0.016,-0.734 0.015,-0.739 0.015,-0.747Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,672.757,99.8802)">
                <path d="M0.34,-0.801L0.365,-0.801C0.371,-0.801 0.375,-0.799 0.378,-0.795C0.381,-0.79 0.383,-0.778 0.385,-0.759L0.396,-0.602C0.396,-0.599 0.396,-0.596 0.396,-0.593C0.396,-0.581 0.395,-0.573 0.391,-0.569C0.387,-0.564 0.379,-0.562 0.367,-0.562C0.353,-0.562 0.343,-0.566 0.337,-0.573C0.332,-0.581 0.326,-0.598 0.321,-0.626C0.308,-0.697 0.289,-0.733 0.264,-0.733C0.244,-0.733 0.233,-0.707 0.233,-0.656L0.233,-0.107C0.233,-0.07 0.244,-0.052 0.266,-0.052C0.293,-0.052 0.308,-0.083 0.312,-0.144C0.313,-0.161 0.316,-0.173 0.322,-0.18C0.328,-0.187 0.34,-0.19 0.358,-0.19C0.372,-0.19 0.381,-0.188 0.386,-0.183C0.392,-0.178 0.395,-0.168 0.395,-0.153C0.395,-0.107 0.381,-0.068 0.353,-0.037C0.325,-0.007 0.29,0.009 0.248,0.009C0.208,0.009 0.172,-0.004 0.142,-0.03C0.111,-0.056 0.085,-0.1 0.062,-0.163C0.04,-0.226 0.029,-0.306 0.029,-0.405C0.029,-0.498 0.038,-0.572 0.057,-0.627C0.076,-0.683 0.1,-0.726 0.129,-0.756C0.158,-0.786 0.192,-0.801 0.23,-0.801C0.254,-0.801 0.274,-0.795 0.292,-0.782C0.298,-0.778 0.303,-0.775 0.306,-0.775C0.31,-0.775 0.316,-0.78 0.323,-0.789C0.329,-0.797 0.335,-0.801 0.34,-0.801Z" />
            </g>
            <g transform="matrix(27.4754,0,0,27.4754,683.919,99.8802)">
                <path d="M0.145,-0.733L0.123,-0.733C0.116,-0.733 0.11,-0.729 0.105,-0.719C0.101,-0.709 0.096,-0.683 0.091,-0.641C0.089,-0.623 0.085,-0.611 0.08,-0.606C0.075,-0.601 0.065,-0.599 0.051,-0.599C0.037,-0.599 0.028,-0.601 0.024,-0.604C0.02,-0.608 0.018,-0.614 0.018,-0.625C0.018,-0.63 0.018,-0.636 0.018,-0.642L0.024,-0.756C0.025,-0.769 0.027,-0.778 0.031,-0.782C0.035,-0.786 0.044,-0.788 0.06,-0.788L0.418,-0.788C0.429,-0.788 0.435,-0.787 0.437,-0.784C0.44,-0.782 0.442,-0.772 0.443,-0.756L0.451,-0.655C0.452,-0.647 0.452,-0.639 0.452,-0.631C0.452,-0.622 0.451,-0.614 0.448,-0.609C0.446,-0.604 0.438,-0.601 0.425,-0.601C0.409,-0.601 0.398,-0.603 0.393,-0.606C0.389,-0.609 0.385,-0.619 0.382,-0.637C0.377,-0.665 0.371,-0.688 0.365,-0.706C0.359,-0.724 0.351,-0.733 0.342,-0.733L0.327,-0.733L0.327,-0.106C0.327,-0.089 0.335,-0.076 0.351,-0.069C0.357,-0.066 0.361,-0.063 0.364,-0.059C0.366,-0.055 0.367,-0.046 0.367,-0.033C0.367,-0.017 0.365,-0.007 0.362,-0.004C0.359,-0.001 0.353,-0 0.342,-0L0.127,-0C0.119,-0 0.113,-0.002 0.111,-0.005C0.108,-0.008 0.107,-0.017 0.107,-0.033C0.107,-0.051 0.112,-0.062 0.122,-0.066C0.132,-0.07 0.138,-0.074 0.141,-0.077C0.144,-0.081 0.145,-0.088 0.145,-0.097L0.145,-0.733Z" />
            </g>
        </g>
        <g transform="matrix(0.65053,5.32665e-17,-5.32665e-17,0.65053,240.356,15.9393)">
            <g transform="matrix(108.665,0,0,108.665,823.251,107.975)">
                <path d="M0.29,-0.423C0.406,-0.393 0.464,-0.325 0.464,-0.218C0.464,-0.173 0.454,-0.133 0.432,-0.099C0.411,-0.065 0.381,-0.038 0.344,-0.02C0.306,-0.001 0.266,0.008 0.224,0.008C0.167,0.008 0.121,-0.009 0.087,-0.044C0.052,-0.079 0.035,-0.12 0.035,-0.167C0.035,-0.19 0.041,-0.21 0.053,-0.227C0.065,-0.243 0.082,-0.251 0.104,-0.251C0.126,-0.251 0.143,-0.244 0.154,-0.229C0.166,-0.215 0.172,-0.197 0.172,-0.175C0.172,-0.167 0.17,-0.152 0.167,-0.131C0.165,-0.122 0.164,-0.113 0.164,-0.106C0.164,-0.092 0.168,-0.08 0.177,-0.07C0.186,-0.06 0.198,-0.055 0.213,-0.055C0.234,-0.055 0.249,-0.064 0.259,-0.081C0.269,-0.098 0.274,-0.125 0.274,-0.163C0.274,-0.173 0.274,-0.183 0.273,-0.192L0.267,-0.313C0.262,-0.337 0.254,-0.354 0.243,-0.365C0.232,-0.376 0.22,-0.383 0.208,-0.385C0.196,-0.388 0.177,-0.389 0.15,-0.389L0.136,-0.389C0.126,-0.389 0.121,-0.393 0.12,-0.403L0.119,-0.424C0.119,-0.427 0.119,-0.429 0.119,-0.431C0.119,-0.446 0.126,-0.453 0.141,-0.453C0.178,-0.453 0.204,-0.456 0.22,-0.463C0.237,-0.469 0.247,-0.479 0.253,-0.492C0.258,-0.505 0.261,-0.533 0.261,-0.577C0.261,-0.639 0.258,-0.681 0.251,-0.701C0.245,-0.722 0.232,-0.732 0.211,-0.732C0.199,-0.732 0.188,-0.728 0.179,-0.72C0.17,-0.712 0.165,-0.701 0.165,-0.688C0.165,-0.68 0.167,-0.668 0.169,-0.652C0.174,-0.629 0.176,-0.612 0.176,-0.601C0.176,-0.582 0.169,-0.567 0.157,-0.556C0.144,-0.545 0.129,-0.54 0.111,-0.54C0.09,-0.54 0.073,-0.548 0.06,-0.565C0.048,-0.583 0.042,-0.604 0.042,-0.63C0.042,-0.675 0.059,-0.714 0.094,-0.748C0.129,-0.782 0.176,-0.799 0.235,-0.799C0.304,-0.799 0.356,-0.778 0.392,-0.736C0.427,-0.695 0.445,-0.649 0.445,-0.599C0.445,-0.56 0.433,-0.525 0.409,-0.494C0.385,-0.463 0.35,-0.441 0.304,-0.428L0.29,-0.423Z" />
            </g>
        </g>
        <g transform="matrix(1,0,0,1,1,0)">
            <path d="M766.965,45.17L769.564,53.171L777.976,53.171L771.171,58.115L773.77,66.116L766.965,61.171L760.159,66.116L762.759,58.115L755.953,53.171L764.365,53.171L766.965,45.17Z" />
        </g>
    </g>
</symbol>
</defs>
</svg>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout